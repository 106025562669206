import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Comments from '../components/comments'
import styled from "@emotion/styled"
import { IoMdArrowBack, IoMdArrowForward } from "react-icons/io";
import { IconContext } from "react-icons";

/* Blog Post Container Styles */
const BlogPostContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  margin-top: 10rem;

  @media (max-width: 768px) {
    width: 85%;
  }
  
  @media (max-width: 425px) {
    width: 90%;
  }
`


const BlogPostTitle = styled.h1`
  font-size: 2.5rem;
  margin: 0;
  text-align: center;
  font-weight: bold;

  @media (max-width: 425px) {
    font-size: 2.2rem;
  }
`

const BlogPostDate = styled.p`
  color: hsla(0, 0%, 0%, 0.7);
  display: block;
  text-align: center;
`

const BlogContentContainer = styled.div`
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin: 0 auto;
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;

  iframe {
    margin: 0 auto;
  }
`

const BlogPostNav = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  list-style: none;
  margin: 0 auto;
  text-transform: uppercase;

  @media (max-width: 450px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
`

const BlogPostNavLink = styled(props => <Link {...props} />)`
  border: none;
  color: #ededed;
  background-color: black;
  padding: 0.75rem;
  margin: 1rem;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 1.1rem;
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: #ededed;
    border: 1px solid black;
    color: black;
    -webkit-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
  }
  
  &:focus {
    outline: none;
  }
  
  @media (max-width: 360px) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`
const BlogPostButton = styled.li`
  margin-top: 2rem;
  @media (max-width: 360px) {
    margin-bottom: 2rem;
  }
`


class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const featuredImg = this.props.data.markdownRemark.frontmatter.featuredImg
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <BlogPostContainer>
          <img src={featuredImg} alt={featuredImg} style={{marginBottom: 0}} class="featuredImg"/>
          <BlogPostTitle>{post.frontmatter.title}</BlogPostTitle>
          <BlogPostDate>{post.frontmatter.date}</BlogPostDate>
          <BlogContentContainer dangerouslySetInnerHTML={{ __html: post.html }} />
          <Comments/>
          <BlogPostNav>
          <IconContext.Provider value={{ className: "blog-post-buttons" }}>
            <BlogPostButton>
              {previous && (
                <BlogPostNavLink to={previous.fields.slug} rel="prev">
                  <IoMdArrowBack /> Older Posts
                </BlogPostNavLink>
              )}
            </BlogPostButton>
            <BlogPostButton>
              {next && (
                <BlogPostNavLink to={next.fields.slug} rel="next">
                  Newer Posts <IoMdArrowForward />
                </BlogPostNavLink>
              )}
            </BlogPostButton>
            </IconContext.Provider>
          </BlogPostNav>
        </BlogPostContainer>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(
      fileAbsolutePath: {regex: "content/blog/"}
      fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        featuredImg
        path
      }
    }
  }
`